import img1 from "../../../assets/imgs/service/service-control-600x747.png";
import ServiceGetStarted from "../../../components/ServiceGetStarted";
import { useTranslation } from "react-i18next";

const CustomerServiceControl = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="product-page-container">
        <div className="product-section-container">
          <div className="right">
            <h1>{t("customer-service-control-title")}</h1>
            <div className="para">
              <h2>{t("customer-service-control-t-1")}</h2>
              <p>{t("customer-service-control-p-1")}</p>
            </div>
            <div className="para">
              <h2>{t("customer-service-control-t-2")}</h2>
              <p>{t("customer-service-control-p-2")}</p>
            </div>
            <div className="para">
              <h2>{t("customer-service-control-t-3")}</h2>
              <p>{t("customer-service-control-p-3")}</p>
            </div>
            <div className="para">
              <h2>{t("customer-service-control-t-4")}</h2>
              <p>{t("customer-service-control-p-4")}</p>
            </div>
          </div>
          <div className="left">
            <img
              src={img1}
              alt="Customer service control with Skyz CRM"
              title="service-control"
            />
          </div>
        </div>
        <ServiceGetStarted />
      </div>
    </>
  );
};

export default CustomerServiceControl;
