import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p>
        Copyright {new Date().getFullYear()} SkysCRM limited | All Rights
        Reserved
      </p>
    </div>
  );
};

export default Footer;
